//Creating a global variable which will indicate
//whether the page is being unloaded or not.
var globalVars = {isBeingUnloaded: false};
const socketConfig = JSON.parse(document.head.querySelector('meta[name="socket"]').content);

function getSocketUrl() {
    return socketConfig.url + ':' + socketConfig.port;
}
//Attach the 'beforeunload' event handler on the window object.
$(window).bind('beforeunload', function () {
//In the event handler set the global variable
//to indicate that the page is being unloaded.
    globalVars.isBeingUnloaded = true;
});

/**
 * function to initialize start_date and end_date on new filters
 * @constructor
 */
function startDateFilters(start_date, end_date) {
    let start = (typeof start_date === 'undefined') ? "#start_date" : start_date;
    let end = (typeof end_date === 'undefined') ? "#end_date" : end_date;

    $(`${start}, ${end}`).datepicker({minDate: new Date(2014, 1 - 1, 1), maxDate: new Date()});
    $(start).change(function () {
        $(end).datepicker("option", "minDate", $(this).val());
    });
}

function CreateJedit(obj) {
    var id_editable = obj.attr('id');
    var url = obj.attr('src-ajax');
    var id_to_save = obj.attr('id-save');
    var type = obj.attr('edit-type');
    var data = null;
    if (type == "select") {
        data = obj.attr('edit-data');
    }
    $('#' + id_editable).editable(url, {
        indicator: 'Saving...',
        tooltip: 'Click to edit...',
        id: 'name',
        height: 25,
        type: type,
        data: data,
        submitdata: {id: id_to_save},
        submit: 'Change',
        callback: function (value, settings) {
            if(typeof jeditCallback == 'function') {
                jeditCallback(value,obj.attr('id'));
            }
            },
        onerror:function(settings, original, xhr){
            if (xhr.status == 422) {
                let response = JSON.parse(xhr.responseText);
                var opts = {
                    icon: 'error',
                    heading: 'Error',
                    text: (typeof response['value'] == 'object') ? response['value'][0] : 'Invalid field values',
                    hideAfter: false
                };
                showNotifications(opts);
            }
            original.reset();
        }
    });
}
/**
 * Do Action requiered for transfer item
 * @param type
 * @param orderID
 */
function itemTransferAction(type, orderID) {
    $.post("/notifications/checkForItemTransfer", {type: type, orderID: orderID})
        .done(function () {
            window.location.reload();
        });
}

function serverErrorMessage() {
    return "There is an unknown error. Please refresh browser and try again, if the error persist contact IT.";
}

/**
 * Trigger Notifications Alerts
 * @param type
 */

function getNotifications(type) {
    if (typeof $('.do_not_call_notifications').val() == 'undefined') {
        $.get("/notifications/get_notifications", {type: type}).done(function (data) {
            /**
             * Need Clock In Response
             */
            if (data.need_clock_in) {
                window.location.href = "/timecard/clockin";
            }
            /**
             * Mileage
             */
            if (data.need_mileage) {
                window.location.href = "/trucks/mileage";
            }
            /**
             * Cycle Count Response
             */
            if (data.need_cycle_count == true) {
                window.location.href = "/inventory/cycle_count";
            }
            /**
             * Recount Message
             */
            if (data.need_re_count) {
                $('.mass_alert').addClass('container').append(data.html_alerts['re_count']).show();
            }
            /**
             * Unfinished Locations Response
             */
            if (data.unfinished_locations == true) {
                $('.mass_alert').addClass('container').append(data.html_alerts['unfinished_locations']).show();
            }
            /**
             *  Check for if there is any transfer waiting transfer
             */
            if (data.items_transfer == true) {
                $('.mass_alert').addClass('container').append(data.html_alerts['item_transfer']).show();
            }
            /**
             * Redirect automatic if we don't have non working key
             * for the previous month
             */
            if (data.need_run_non_working_keys_report === true && window.location.pathname !== "/invoice/non_working_keys") {
                window.location.href = "/invoice/non_working_keys?automatic=true";
            }
        });
    }
}

/**
 * function to show notifications
 * @param options array to overwrite properties
 * required:
 * icon(info, success, warning, error)
 * text(message body)
 * suggested:
 * heading(hedding text, can match with icon)
 */
var showNotifications = function (options) {
    var notification = {
        "icon": "success",
        "heading": "Success",
        "text": "Action was completed successfully",
        "closeButton": true,
        "debug": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "1000",
        "hideDuration": "1000",
        "timeOut": "7000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
        "position": "top-right",
        "hideAfter": "5000",
    };
    $.extend(notification, options);
    $.toast(notification);
};
$(document).ajaxError(function (event, jqxhr, settings, thrownError) {
    // intercept incomplete calls
    if (globalVars.isBeingUnloaded) {
        return;
    }
    // avoid cancelled (0), laravel (422) and unauthorized (401) from general errors
    var ignoreCodes = [0, 422, 401];
    if ($.inArray(jqxhr.status, ignoreCodes) == -1) {
        var opts = {
            icon: 'error',
            heading: 'Error',
            text: jqxhr.status + ' ' + serverErrorMessage(),
            hideAfter: false
        };
        showNotifications(opts);
    }
    ;
});

/**
 * return true if this is an special key
 * false otherwise
 * @param key
 */
function isSpecialKey(key) {
    if (key == 20 /* Caps lock */ || key == 8 /* BackSpace */
        || key == 16 /* Shift */
        || key == 9 /* Tab */
        || key == 27 /* Escape Key */
        || key == 17 /* Control Key */
        || key == 91 /* Windows Command Key */
        || key == 19 /* Pause Break */
        || key == 18 /* Alt Key */
        || key == 93 /* Right Click Point Key */
        || ( key >= 35 && key <= 40 ) /* Home, End, Arrow Keys */
        || key == 45 /* Insert Key */
        || ( key >= 33 && key <= 34 ) /*Page Down, Page Up */
        || (key >= 112 && key <= 123) /* F1 - F12 */
        || (key >= 144 && key <= 145 )) { /* Num Lock, Scroll Lock */
        return true;
    }
    return false;
}
/**
 * Update Notification count
 */
function updateNotificationCount(data){
    data = typeof data !== 'undefined' ?  true : false;
    var notification_count = $('#notifications_counts').text();
    if(data){
        if(notification_count <= 1){
            notification_count = '';
        }
        else{
            notification_count--;
        }
    }
    else{
        if(notification_count == ''){
            notification_count = 1;
        }
        else{
            notification_count++;
        }
    }
    $('#notifications_counts').text(notification_count);
}

function currency(value, places) {
    if (typeof places == 'undefined') {
        places = 2;
    }
    if (!value) return '$0.00';
    return '$' + parseFloat(value).toFixed(places);
}

$(function () {

    /**
     * Pipeline function for DataTables. To be used to the `ajax` option of DataTables
     * @param opts
     * @returns {Function}
     */
    $.fn.dataTable.pipeline = function ( opts ) {
        // Configuration options
        var conf = $.extend( {
            pages: 2,     // number of pages to cache
            url: '',      // script url
            data: null,   // function or object with parameters to send to the server
                          // matching how `ajax.data` works in DataTables
            method: 'GET' // Ajax HTTP method
        }, opts );

        // Private variables for storing the cache
        var cacheLower = -1;
        var cacheUpper = null;
        var cacheLastRequest = null;
        var cacheLastJson = null;

        return function ( request, drawCallback, settings ) {
            var ajax          = false;
            var requestStart  = request.start;
            var drawStart     = request.start;
            var requestLength = request.length;
            var requestEnd    = requestStart + requestLength;

            if ( settings.clearCache ) {
                // API requested that the cache be cleared
                ajax = true;
                settings.clearCache = false;
            }
            else if ( cacheLower < 0 || requestStart < cacheLower || requestEnd > cacheUpper ) {
                // outside cached data - need to make a request
                ajax = true;
            }
            else if ( JSON.stringify( request.order )   !== JSON.stringify( cacheLastRequest.order ) ||
                JSON.stringify( request.columns ) !== JSON.stringify( cacheLastRequest.columns ) ||
                JSON.stringify( request.search )  !== JSON.stringify( cacheLastRequest.search )
            ) {
                // properties changed (ordering, columns, searching)
                ajax = true;
            }

            // Store the request for checking next time around
            cacheLastRequest = $.extend( true, {}, request );

            if ( ajax ) {
                // Need data from the server
                if ( requestStart < cacheLower ) {
                    requestStart = requestStart - (requestLength*(conf.pages-1));

                    if ( requestStart < 0 ) {
                        requestStart = 0;
                    }
                }

                cacheLower = requestStart;
                cacheUpper = requestStart + (requestLength * conf.pages);

                request.start = requestStart;
                request.length = requestLength*conf.pages;

                // Provide the same `data` options as DataTables.
                if ( $.isFunction ( conf.data ) ) {
                    // As a function it is executed with the data object as an arg
                    // for manipulation. If an object is returned, it is used as the
                    // data object to submit
                    var d = conf.data( request );
                    if ( d ) {
                        $.extend( request, d );
                    }
                }
                else if ( $.isPlainObject( conf.data ) ) {
                    // As an object, the data given extends the default
                    $.extend( request, conf.data );
                }
                var table_id = $('[data-dataTable-server-side]').attr('id');
                $('#'+table_id+'_info').parent().parent().hide();
                $('#'+table_id+'>tbody').html('');
                settings.jqXHR = $.ajax( {
                    "type":     conf.method,
                    "url":      conf.url,
                    "data":     request,
                    "dataType": "json",
                    "cache":    false,
                    "success":  function ( json ) {
                        cacheLastJson = $.extend(true, {}, json);

                        if ( cacheLower != drawStart ) {
                            json.data.splice( 0, drawStart-cacheLower );
                        }
                        if ( requestLength >= -1 ) {
                            json.data.splice( requestLength, json.data.length );
                        }
                        $('#'+table_id+'_info').parent().parent().show();
                        drawCallback( json );
                    }
                } );
            }
            else {
                json = $.extend( true, {}, cacheLastJson );
                json.draw = request.draw; // Update the echo for each response
                json.data.splice( 0, requestStart-cacheLower );
                json.data.splice( requestLength, json.data.length );

                drawCallback(json);
            }
        }
    };

    /**
     * Register an API method that will empty the pipelined data, forcing an Ajax
     * fetch on the next draw (i.e. `table.clearPipeline().draw()`)
     */
    $.fn.dataTable.Api.register( 'clearPipeline()', function () {
        return this.iterator( 'table', function ( settings ) {
            settings.clearCache = true;
        } );
    } );



    /**
     * Socket section
     */
    var socket = io(getSocketUrl());
    var user_id = $('#header-master-layout').attr('data-user-id');

    socket.on('Key-code:decline_title_' + user_id, function (data) {
        showNotifications({
            icon: 'error',
            heading: 'Bad Paperwork',
            text: 'Key Code request was decline because had bad paperwork. Vin Number: ' + '<a id= "lookKeyProblem" data-id="'+ data.vin +'" data-date="'+ data.order_date +'">' + data.vin + '</a>',
            "hideAfter": false,
        });
    });

    $(document).on('click',"#lookKeyProblem", function(){
        var data_vin = $(this).attr('data-id');
        var data_date = $(this).attr('data-date');

        var form = '<form action="/orders/code_order/tech" method="POST" id="find_key_code_problem">' +
                        '<input type="hidden" name="vin" value="' + data_vin + '">' +
                        '<input type="hidden" name="start_date" value="' + data_date + '">' +
                    '</form>';
        $(document.body).append(form);
        $(document.body.querySelector('#find_key_code_problem')).submit();
    });
    socket.on('News-Channel:itemsAverage' + user_id, (data) => {
        if (data.has_error) {
            showNotifications({
                "icon": "error",
                "heading": "Something went wrong",
                "hideAfter": false,
                "text": `${ data.message }`
            })
        } else {
            showNotifications({
                "icon": "notification",
                "heading": "Items Average Cache Completed",
                "hideAfter": false,
                "text": `${ data.message } please <a href="javascript:location.reload()">reload the page</a>`
            })
        }
    });

    socket.on('RKO-Request:create'+user_id, function (data){
        showNotifications({
            "icon": "notification",
            "heading": "RKO Notification Message",
            "hideAfter": false,
            "text": data.notification_message + ", vehicle: "+ data.vehicle_name + " | Vin Number: "+ data.vin_number +" | Service Location: "+ data.repo.name +" | Service Phone: "+ data.repo.phone +" | Lender: "+ data.lender.name +" please click <a href='" + data.confirm_link + "'> here</a> to confirm this RKO",
        });
    });

    socket.on('RKO-Request:update'+user_id, function (data){
        showNotifications({
            "icon": "notification",
            "heading": "RKO Notification Message",
            "hideAfter": false,
            "text": data.notification_message + ", vehicle: "+ data.vehicle_name +" | Vin Number: "+ data.vin_number +" | Service Location: "+ data.repo.name +" | Lender: "+ data.lender.name +"  please click <a href='/orders/rko/list'> here</a> to view this RKO",
        });
    });

    socket.on('RKO-Problem:Problem'+user_id, function (data){
        showNotifications({
            "icon": "error",
            "heading": "Notification RKO Problem",
            "hideAfter": false,
            "text": "RKO problem,vehicle: "+ data.rok_request.vehicle_name + " | Vin Number: "+ data.rok_request.vin_number +" | Service Location: "+ data.rok_request.repo.name +" | Service Phone: "+ data.rok_request.repo.phone +"  please click <a href='/orders/rko/list'> here</a> to view this RKO",
        });
    });
    socket.on('RKO-Problem:Comment'+user_id, function (data){
        showNotifications({
            "icon": "notification",
            "heading": "Notification RKO Comment",
            "hideAfter": false,
            "text": "RKO Comment,vehicle: "+ data.rok_request.vehicle_name + "| Vin Number: "+ data.rok_request.vin_number +" | Service Location: "+ data.rok_request.repo.name +" | Service Phone: "+ data.rok_request.repo.phone +"  please click <a href='/orders/rko/list'> here</a> to view this RKO",
        });
    });

    socket.on('Customer:cancelled'+user_id, function (data){
        showNotifications({
            "icon": "error",
            "heading": "Customer Cancelled",
            "hideAfter": false,
            "text": "Customer " + data.dealer_name + " was cancelled",
        });
    });
    socket.on('Customer:approved'+user_id, function (data){
        showNotifications({
            "icon": "notification",
            "heading": "Customer Approved",
            "hideAfter": false,
            "text": "Customer "+ data.dealer_name + "was approved",
        });
    });
    /**
     * Socket to update administration news and updates
     *
     * Remember that to this socket notification style function you
     * need to add notification to _defaultIcon array in the plugin code.
     */
    socket.on('News-Channel:create', function (data) {

        var this_user = parseInt($('#header-master-layout').attr('data-user-id'));
        var heading = (data.alert_type != null && data.alert_type == 'important') ? 'Important Notification' : 'Notification';
        updateNotificationCount();
        if($('#header-master-layout').length){
            if (data.config_type == 'all') {
                showNotifications({
                    'text': data.text,
                    "icon": 'notification',
                    "heading": heading,
                    "hideAfter": false,
                });
                updateNotificationTable(data);
            }
            else {
                var recipients = JSON.parse(data.recipients_id);
                for (var i in recipients) {
                    if (recipients[i] == this_user) {
                        showNotifications({
                            'text': data.text,
                            "icon": 'notification',
                            "heading": heading,
                            "hideAfter": false,
                        });
                        updateNotificationTable(data);
                        break;
                    }
                }
            }
        }

    });
    /**
     * Update notification
     * @param data
     */
    function updateNotificationTable(data){
        if($('#notifications-table-data').length){
            table.destroy();
            showLoadingTable(getNotificationType());
        }
    }
    socket.on('Updated-Read:read', function (data) {
        var this_user = parseInt($('#header-master-layout').attr('data-user-id'));
        if( data.user_id == this_user){
            updateNotificationCount(data);
        }
    });

    socket.on('News-Channel:none', function (data) {
        if (typeof showSocketNotification == 'function') {
            showSocketNotification(data);
        }
    });

    socket.on('OrderHeader-channel:order_reject', function(data){
        var this_user = parseInt($('#header-master-layout').attr('data-user-id'));
        if( data.user_id == this_user){
            showNotifications({
                "icon": "info",
                "heading": "Order Reject",
                "text": data.message,
                "closeButton": false,
                "hideAfter": false,
            });
        }
    });

    /**
     * Socket when the requested key code was sent from office to tech
     */
    socket.on('Key-code:send',function(data){
        var this_user = parseInt($('#header-master-layout').attr('data-user-id'));
        if( data.user_id == this_user){

            showNotifications({
                "icon": "success",
                "heading": "Requested Code",
                "text": data.message,
                "closeButton": false,
                "hideAfter": false,
            });
            updateNotificationCount();
            updateNotificationTable(data);

        }
    });

    /**
     * ini plugins
     */
    $("select:not('.no_js')").select2({
        width: '100%'
    });
    /**
     * enable search function of select2 on bootstrap modals
     */
    $.fn.modal.Constructor.prototype.enforceFocus = function () {
    };
    /**
     * Tour Code
     */
    if (typeof(tl) == "object") {
        tl.pg.init({
            custom_open_button: '#quick_help_menu_link'
        });
        $('#quick_help_menu_link').parent().click(function (e) {
            e.preventDefault();
        });
    }
    else {
        $('#quick_help_menu_link').parent().addClass('disabled');
    }

    /**
     * Notifications
     */
    getNotifications('all');
    setInterval(function () {
        getNotifications('clockInOnly');
    }, 1800000/*1800000*/);//every 30 min Call
    /**
     * Close Location Modal
     */
    $('.close_location').click(function () {
        $('.misscar_location').val('0');
        $('.id_loc').val($(this).attr('id_location'));
        $('.loc_type').val($(this).attr('storeType'));
        $('.carmax_input').addClass('hide');
        $('#off_location').modal('show');
    });

    /**
     * CODE FOR SHORT GO OFF LOCATION
     */
    $('.misscar_location').change(function () {
        var value = $(this).val();
        var store_type = $('.loc_type').val();
        if (value == 1) {
            window.location.href = "/miscar";
        }
        else if (value == 2) {
            if (store_type == 'CARMAX' || store_type == 'KAR') {
                $('.carmax_input').removeClass('hide');
            }
            else {
                var form = $('#short_off_location');
                $.post(form.prop('action'), form.serialize()).done(function () {
                    window.location.reload();
                });
            }
        }
    });
    /*CARMAX ONLOCATION OFF*/
    $('#short_off_location').submit(function (e) {
        $.ajax({
            url: $(this).prop('action'),
            type: 'POST',
            data: new FormData(this),
            processData: false,
            contentType: false
        }).done(function (response) {
            if (response != '')
                $('.carmax_sheet_error').text(response).removeClass('hide');
            else  window.location.reload();
        });
        e.preventDefault();
    });
    /*Trigger the form*/
    $('#carmax_route_sheet').change(function () {
        var form = $('#short_off_location');
        form.submit();
    });

    /**
     * FUNCTION FOR SEND AJAX FORMS
     */
    $('form[data-remote]').on('submit', function (e) {
        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        var url = form.prop('action');
        $("form[data-remote] :submit,.send_form_ajax_button").prop('disabled', true);
        $.ajax({
            type: method,
            url: url,
            data: form.serialize(),
            success: function (data) {
                var value = $.parseJSON(data);
                $("form[data-remote] :submit,.send_form_ajax_button").prop('disabled', false);
                window[value.fn_callback](form, value);
            }
        })
        /**
         *  Create a custom validation from server response
         */
            .error(function (data) {
                var errors = data.responseJSON;
                $("form[data-remote] :submit,.send_form_ajax_button").prop('disabled', false);
                if (typeof errors != 'undefined' && typeof(errorsCallback) == "function") {
                    if (errorsCallback(errors)) {
                        return
                    }
                    ;
                }
                alert(serverErrorMessage());
            });
        e.preventDefault();
    });
    /*FROM THEME*/
    // Collapse ibox function
    $('.collapse-link').click(function () {
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        var content = ibox.find('div.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    });

    // Close ibox function
    $('.close-link').click(function () {
        var content = $(this).closest('div.ibox');
        content.remove();
    });

    // Move modal to body
    // Fix Bootstrap backdrop issue with animation.css
    $('.modal').appendTo("body");

    // tooltips
    $('.tooltip-demo').tooltip({
        selector: "[data-toggle=tooltip]",
        container: "body"
    });

    //EDIT USING IEDIT + AJAX SAVE FUNCTION
    $('.edit').hover(function () {
        CreateJedit($(this));
    });

    //CODE FOR MODAL DELETE SECURE
    function clearConfirmHardCopy() {
        $('#confirm_delete_secure_modal').val('');
        $("#send_to_secure_modal_action").attr('disabled', 'disabled');
        $('#send_to_secure_modal_action').removeClass().addClass('btn btn-danger');
        $('#send_to_secure_modal_action').removeAttr('click');
    }


    $('a[secure-delete],button[secure-delete]').on('click', function (e) {
        clearConfirmHardCopy();
        if ($(this).attr('modal-id')) {
            $('#id_to_delete_secure_modal').val($(this).attr('modal-id'));
            $('#send_to_secure_modal_action').addClass('send_to_secure_modal_action_' + $(this).attr('modal-id'));
        }
        else {
            alert("Object Id Its Required!!");
        }
        if ($(this).attr('modal-title')) {
            $('.assets_delete_title').text($(this).attr('modal-title'));
        }
        if ($(this).attr('modal-text')) {
            $('.assets_delete_text').text($(this).attr('modal-text'));
        }
        if ($(this).attr('modal-button')) {
            $('.button-delete-text').text($(this).attr('modal-button'));
        }
        if ($(this).attr('modal-button-icon')) {
            $('.button-delete-icon').removeClass('fa-trash-o').addClass('fa-' + $(this).attr('modal-button-icon'));
        }
        if ($(this).attr('modal-onclick')) {
            $('#send_to_secure_modal_action').attr('onClick', $(this).attr('modal-onclick'));
        }
        if ($(this).attr('modal-secure-text')) {
            $('#confirm_delete_secure_modal').attr('Placeholder', $(this).attr('modal-secure-text'));
            $('.delete_modal_verified_text').text($(this).attr('modal-secure-text'))
        }
        $('.delete_ajax_modal').modal('show');
        e.preventDefault();
    });


    /**
     * Change to Hard Body
     */
    $('#confirm_delete_secure_modal').on('change paste keyup', function () {
        var value = $(this).val();
        var secure_word = $(this).attr('Placeholder');
        if (value === secure_word) {
            $('#send_to_secure_modal_action').removeAttr('disabled');
        }
        else {
            $("#send_to_secure_modal_action").attr('disabled', 'disabled');
        }
    });

    /**
     * Search
     */
    $('.search_website_open').click(function (e) {
        e.preventDefault();
        $(this).hide();
        $('.search_website_div').show('slow');
    });
    $('.close_website_search').click(function (e) {
        e.preventDefault();
        $('.search_website_div').hide('slow', function () {
            $('.search_website_open').show();
        });
    });

    $('.dropdown-menu,.li_link').click(function (e) {
        e.stopPropagation();
    });

    $('.checkbox-success').click(function () {
        var checkbox = $(this).children(':checkbox');
        checkbox.prop("checked", !checkbox.prop("checked"));
    });

    $('#menu_search_form').submit(function () {
        var search = $('#menu_search_input');
        search.parent().parent().removeClass('has-error');
        if (search.val() == '') {
            search.parent().parent().addClass('has-error');
            return false;
        }
    });
    /**
     * Allows only numbers with . as decimal separator
     */
    $('div').on('keydown', '.only_numbers', function (event) {
        if (event.shiftKey == true) {
            event.preventDefault();
        }
        if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 46 || event.keyCode == 190  || event.keyCode == 110) {
        } else {
            event.preventDefault();
        }
        if ($(this).val().indexOf('.') !== -1 && (event.keyCode == 190 || event.keyCode == 110))
            event.preventDefault();
    });
    /**
     * Allows only digits
     */
    $('div').on('keydown', '.only_digits', function (event) {
        if (event.shiftKey == true) {
            event.preventDefault();
        }
        if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 37 || event.keyCode == 39 )
        {
        } else {
            event.preventDefault();
        }
    });


});

/**
 * Fix for IE < 9
 */
if (!Object.keys) {
    Object.prototype.keys = function(obj) {
        var keys = [];

        for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
                keys.push(i);
            }
        }

        return keys;
    };
}

var JQErrors = function () {

    this.errors = {};

    this.set = function (errors) {
        this.errors = errors;
        this._watch();
    }

    this.add = function (type, message) {
        if(typeof this.errors[type] == 'undefined'){
            this.errors[type] = [];
        }

        this.errors[type].push(message);
        this._watch();
    }

    this.get = function (type) {
        return this.errors[type];
    }

    this.first = function (type) {
        return this.get(type)[0];
    }

    this.has = function (type) {
        var attr = Object.keys(this.errors);
        return (attr.length > 0 && (typeof type != 'undefined' ? attr.indexOf(type) != -1 : true));
    }

    this.remove = function (type) {
        delete this.errors[type];
        this._watch();
    }

    this.reset = function () {
        this.errors = {};
        this._watch();
    }

    this._watch = function(){
        $('.jq-error').text('').hide();
        var keys = Object.keys(this.errors);

        for(var i in keys){
            if(this.has(keys[i])){
                $('.jq-error[data-jq-for="'+keys[i]+'"]').addClass('text-danger').text(this.first(keys[i])).show();
            }
        }
    }
};

function extractUrlParams(url) {
    var regex = /[?&]([^=#]+)=([^&#]*)/g;
    var data = {};
    var match;

    while (match = regex.exec(url)) {
        data[match[1]] = match[2];
    }

    return data;
}

window.Asset = {
    baseUrl: '',

    /**
     * Init the Base Url Attribute
     *
     * @returns {*}
     */
    init: function(){
        let assetPath = document.head.querySelector('meta[name="asset-path"]');

        if (assetPath) {
            return this.baseUrl = this.trim(assetPath.content);
        }

        console.error('Asset Path not found: Asset Path (asset-path) not set meta in header.');
    },

    /**
     * Get the Full Path of the Asset
     * [S3 or Host]
     *
     * @param path
     */
    get: function(path){
        if(!this.baseUrl){
            this.init();
        }

        path = !path ? '/' : path;

        return this.baseUrl + '/' + this.trim(path);
    },

    /**
     * Trim spaces and slashes from start and end of strings
     *
     * @param string
     * @returns {*}
     */
    trim: function(string){
        return string.replace(/^\s+|\s+$|\/$|^\//g, '');
    },
};




