$(function() {
	
	
	var items = $('.overlapblackbg, .slideLeft');
	var wsmenucontent = $('.wsmenucontent');
	
	var menuopen = function() {
	$(items).removeClass('menuclose').addClass('menuopen');
						}
	var menuclose = function() { 
	$(items).removeClass('menuopen').addClass('menuclose');
	}

	$('#navToggle').click(function(){
		if (wsmenucontent.hasClass('menuopen')) {$(menuclose)}
		else {$(menuopen)}
	});
	wsmenucontent.click(function(){
		if (wsmenucontent.hasClass('menuopen')) {$(menuclose)}
	});
	
	$('#navToggle,.overlapblackbg').on('click', function(){
	$('.wsmenucontainer').toggleClass( "mrginleft" );
	});

	$('.wsmenu-list li').has('.wsmenu-submenu, .wsmenu-submenu-sub, .wsmenu-submenu-sub-sub').prepend('<span class="wsmenu-click"><i class="wsmenu-arrow fa fa-angle-down"></i></span>');
	
	$('.wsmenu-list li').has('.megamenu').prepend('<span class="wsmenu-click"><i class="wsmenu-arrow fa fa-angle-down"></i></span>');
		
	$('.wsmenu-mobile').click(function(){
		$('.wsmenu-list').slideToggle('slow');
	});
	
	
	$('.wsmenu-click').click(function(){
	$(this).siblings('.wsmenu-submenu').slideToggle('slow');
	$(this).children('.wsmenu-arrow').toggleClass('wsmenu-rotate');
	$(this).siblings('.wsmenu-submenu-sub').slideToggle('slow');
	$(this).siblings('.wsmenu-submenu-sub-sub').slideToggle('slow');
	$(this).siblings('.megamenu').slideToggle('slow');
		
	});


    $('#sendMessageModal').on('hidden.bs.modal', function () {
        cleanFormAndMessage();
    });

    $('#clear_chose_message').click(function (){
        cleanFormAndMessage();
    });

    $('#checkbox_send_to_all').on('change',function (e){

        if(!$("#checkbox_send_to_all").prop("checked"))
        {
            $("#checkbox_send_to_all").prop("checked",false);
            $("#recipients").attr('disabled', false).trigger("change");
        }else
        {
            $("#checkbox_send_to_all").prop("checked",true);
            $("#recipients").attr('disabled', true).trigger("change");
        }

    });

    $('#sendMessageLinkMenu').click(function (e) {
        e.preventDefault();
        if ($('#sendMessageModal').hasClass('first')) {
            $.post("/load_recipients" )
                .done(function (data) {
                    var html = '';
                    $.each(data.recipients, function (i, obj) {
                        html += ' <option value='+i+'>' + obj + '</option>';
                    });
                    $('#recipients').html(html);
                }).complete(function(){
                $('#sendMessageModal').modal('show');
            });
            $('#sendMessageModal').removeClass('first');
        }else{
        $('#sendMessageModal').modal('show');
        }
    });



    $('#send_message_through_gcm').on('submit', function (e) {

       if($('#recipients').val()==null && !$("#checkbox_send_to_all").prop("checked")){
            e.preventDefault();
            Showessage('notification_sending_message','Please select at least one recipient','alert alert-danger');
        }
        else if($('#text_body').val()==''){
            e.preventDefault();
            Showessage('notification_sending_message','Please type a message','alert alert-danger');
        }
        else{
            $('#send_message').addClass('disabled').html('<i class="fa fa-send-o"></i> sending...');
            e.preventDefault();
            var form = $(this);
            $.ajax({
                type: 'POST',
                url: '/send_message_gcm',
                data: form.serialize(),
                success: function (data) {
                    $('#send_message').removeClass('disabled').html('<i class="fa fa-envelope-o"></i> Send');

                    Showessage('notification_sending_message',data.message,data.data_class);
                    if(data.status_code == '200' ){

                        $('#close_send_message,#clear_chose_message,#send_remove_store_request').addClass('disabled');
                        setTimeout(function () {
                            $("#sendMessageModal").modal('hide');
                        }, 3000);
                        $('body').removeClass('modal-open');
                    }
                    else
                    {
                        setTimeout(function () {
                            $("#notification_sending_message").addClass('hide');
                        },5000);
                    }
                },
                failure : function(){
                    $('#send_message').removeClass('disabled').html('<i class="fa fa-envelope-o"></i> Send');
                }
            });

             }


    });

    function Showessage(elemento,message,message_class) {

        $('#'+elemento).removeClass('hide').addClass(message_class).html(message);
        setTimeout(function () {
            $("#elemento").addClass('hide');
        },5000);
    }

    function cleanFormAndMessage(){
        if($("#checkbox_send_to_all").prop("checked")){
        $("#checkbox_send_to_all").prop("checked",false);
        $("#recipients").attr('disabled', false).trigger("change");
        }
        $('#send_message').removeClass('disabled').html('<i class="fa fa-envelope-o"></i> Send');
        $('#close_send_message,#clear_chose_message,#send_remove_store_request').removeClass('disabled');
        $('#recipients option').prop('selected', false).trigger("change");
        $('#text_body').val('');
        $('#notification_sending_message').addClass('hide');
    }
});